import $ from "jquery";
window.$ = $;
window.jQuery = $;

import './bootstrap';

import './../../vendor/power-components/livewire-powergrid/dist/powergrid';

import ps from "perfect-scrollbar";
window.ps = ps;
window.PerfectScrollbar = ps;

document.querySelectorAll(".perfect-scrollbar, [data-perfect-scrollbar]").forEach(function(element) {
    var ps = new PerfectScrollbar(element, {
        suppressScrollX: element.dataset["suppress-scroll-x"],
        suppressScrollY: element.dataset["suppress-scroll-y"]
    });
});

import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
window.intlTelInput = intlTelInput;

import * as toastr from 'toastr';
window.toastr = toastr;

import Swal from 'sweetalert2';
window.Swal = Swal;

import * as echarts from 'echarts';
window.echarts = echarts;

import bootstrapDatepicker from 'bootstrap-datepicker';
window.datepicker = bootstrapDatepicker;

import * as noUiSlider from 'nouislider';
window.noUiSlider = noUiSlider;